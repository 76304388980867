import scrollTo from '../components/scrollTo/scrollTo';

export default class TabsAccordion {
    constructor(element) {
        this.tabContainers = document.querySelectorAll(element);
    }

    init() {
        this.tabContainers.forEach(tabContainer => {
            this.toggleTabs(tabContainer);
        });
    }

    toggleClass(otherElems, thisElem, className = 'is-active') {
        otherElems.forEach(otherElem => {
            otherElem.classList.remove(className);
        });
        thisElem.classList.add(className);
    }

    toggleVerticalTabs(tabContainer, tabs, items, item) {
        item.onclick = () => {
            const currId = item.getAttribute('id');
            const tab = tabContainer.querySelector(`.tabs-accordion--tabs [aria-controls="${currId}"]`);
            this.toggleClass(tabs, tab);
            this.toggleClass(items, item);
            if (window.innerWidth < 992) {
                scrollTo(item, -160);
            }
        };
    }

    toggleTabs(tabContainer) {
        const tabs = tabContainer.querySelectorAll('.tabs-accordion--tabs .tab');
        const items = tabContainer.querySelectorAll('.tabs-accordion--entry');
        
        tabs.forEach(tab => {
            tab.onclick = () => {
                const target = tab.getAttribute('aria-controls');
                const content = document.getElementById(target);
                this.toggleClass(tabs, tab);
                this.toggleClass(items, content);
            };
        });

        items.forEach(item => {
            this.toggleVerticalTabs(tabContainer, tabs, items, item);
        });
    }
}