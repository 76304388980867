//
// Orders history
//

(($) => {
    $(() => {
        const module = $('.orders-container-web-shop');

        // Only continue if on product view page
        if (!module.length) return;

        module.find('.toggle-details-web-orders').on('click', (e) => {
            const target = $(e.currentTarget);
            target.attr('aria-expanded', target.attr('aria-expanded') !== 'true');
            target.parent().siblings('div.order-items-details-container').toggleClass('show');
            target.parents('div.order-row').toggleClass('collapsed');
        });
    });
})(jQuery);