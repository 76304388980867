;(($) => {
    $(() => {
        const moduleSelector = '.hubspot-widget';
        const module = $(moduleSelector);

        // Only continue if module is on page
        if (!module.length) {
            return;
        }

        module.empty();
        const region = module.attr('data-region');
        const portalId = module.attr('data-portalid');
        const formId = module.attr('data-formid');
        const scriptUrl = `//js-${region}.hsforms.net/forms/v2.js`;

        const loadScript = (scriptUrl) => {
            const script = document.createElement('script');
            script.src = scriptUrl;
            document.body.appendChild(script);

            return new Promise((resolve, reject) => {
                script.onload = () => resolve();
                script.onerror = () => reject();
            });
        };

        const renderForm = () => {
            // eslint-disable-next-line no-undef
            hbspt.forms.create({ region, portalId, formId, target: moduleSelector });
        };

        loadScript(scriptUrl)
            .then(renderForm)
            .catch(() => {});
    });
})(jQuery);