import prepareFilterData from '../../filter-common/prepareFilterData'
import { startLoader, stopLoader } from '../../filter-common/filterLoaders'
import { getPage } from '../../api/search-results'
import scrollTo from '../../components/scrollTo/scrollTo'
import { updateUrlParam, getUrlParam, deleteUrlParam } from '../../url-params/url-params'
import { getSortingParams } from '../../filter-common/sortingResults'
import { LazyLoadImages } from '../../lazy-loading/LazyLoadImages'

let isLast = true
export default class FilterResults {
    constructor(hideBulletPoints) {
        this.page = parseInt(getUrlParam('page')) || 1
        this.isLoading = true
        this.hideBulletPoints = hideBulletPoints
        this.productListing = document.getElementById('product_listing')
        this.productCountContainer = document.getElementById('product_listing_count')
        this.hostOrigin = window.location.origin
        this.promises = []
    }

    generateFeature(feature) {
        const featureWrapper = document.createElement('li')
        let featureKey = this.cutBR(feature.key)
        const featureValue = feature.value
        const featureUnit = feature.unit
        const featureType = typeof featureValue
        let transformedValue = ''
        if (featureType === 'object') {
            for (let [key, value] of Object.entries(featureValue)) {
                if (key === 'min') {
                    transformedValue += `${value} - `
                } else if (key === 'max') {
                    transformedValue += `${value}`
                } else {
                    transformedValue += `${value}, `
                }
            }
        } else {
            transformedValue = featureValue
        }
        transformedValue = transformedValue.replace(/(,\s?)$/g, '')
        if (typeof featureUnit === 'string' && featureUnit.length > 0) {
            transformedValue += ` ${featureUnit}`
        }
        featureKey = this.cutBR(featureKey)
        transformedValue = this.cutBR(transformedValue)

        featureWrapper.setAttribute('title', `${featureKey}: ${transformedValue}`)
        featureWrapper.innerHTML = `${featureKey}: ${transformedValue}`

        return featureWrapper
    }

    generateProductImage(product) {
        const productImageWrapper = document.createElement('a')
        productImageWrapper.classList.add('item-image')
        productImageWrapper.href = this.generateProductUrl(product.url)
        productImageWrapper.addEventListener('click', () => this.refreshUrl(product))
        const productImage = document.createElement('img')
        productImage.classList.add('image', 'lazy')
        productImage.setAttribute('data-src', product.image)
        productImage.setAttribute('alt', product.imageAlt)
        productImage.setAttribute('width', '300')
        productImage.setAttribute('height', '300')
        productImageWrapper.appendChild(productImage)
        return productImageWrapper
    }

    generateProductDescription(product) {
        const text = product.listingText
        const attributes = product.listingAttributes
        const name = product.name
        const hasProductText = text !== ''
        const hasAttributes = attributes.length > 0

        const productDescWrapper = document.createElement('div')
        productDescWrapper.classList.add('item-desc')
        const aProductName = document.createElement('a')
        aProductName.href = this.generateProductUrl(product.url)
        const productName = document.createElement('h2')
        productName.classList.add('item-name')
        productName.innerHTML = name
        aProductName.appendChild(productName)
        productDescWrapper.appendChild(aProductName)

        if (hasProductText || hasAttributes) {
            const scrollWrapper = document.createElement('div')
            scrollWrapper.classList.add('scrollable-group')
            if (hasProductText) {
                const textElem = document.createElement('div')
                textElem.classList.add('item-text')
                textElem.innerHTML = text
                scrollWrapper.appendChild(textElem)
            }
            if (hasAttributes) {
                const listElem = document.createElement('ul')
                listElem.classList.add('item-features')
                if (!this.hideBulletPoints) {
                    listElem.classList.add('with-bullet')
                }
                attributes.map((attr) => {
                    listElem.appendChild(this.generateFeature(attr))
                })
                scrollWrapper.appendChild(listElem)
            }
            productDescWrapper.appendChild(scrollWrapper)
        } else {
            const emptyElem = document.createElement('div')
            emptyElem.classList.add('item-empty')
            productDescWrapper.appendChild(emptyElem)
        }

        return productDescWrapper
    }

    generateProductButton(product) {
        const productButtonWrapper = document.createElement('div')
        productButtonWrapper.classList.add('item-button')
        const productButton = document.createElement('a')
        productButton.classList.add('button')
        productButton.href = this.generateProductUrl(product.url)
        productButton.innerHTML = `${window.globals.trans('filter.view_product')}`
        productButton.addEventListener('click', () => this.refreshUrl(product))
        productButtonWrapper.appendChild(productButton)
        return productButtonWrapper
    }

    generateProductUrl(url) {
        const params = window.location.href.split('#')[1] || ''
        const newUrl = `${url}#${params}`
        return newUrl
    }

    refreshUrl(product) {
        updateUrlParam('page', product.page)
        updateUrlParam('productId', product.id)
    }

    generateProduct(product) {
        const pWrapper = document.createElement('div')
        pWrapper.href = this.generateProductUrl(product.url)
        pWrapper.addEventListener('click', () => this.refreshUrl(product))
        pWrapper.classList.add('product-wrapper')
        pWrapper.setAttribute('data-product-id', product.id)
        const pUrl = document.createElement('div')
        pUrl.classList.add('item')
        const pImage = this.generateProductImage(product)
        const pDescription = this.generateProductDescription(product)
        const pPriceButtonWrapper = this.generatePriceButtonWrapper()
        const pPrice = this.generateProductPrice(product)
        const pButton = this.generateProductButton(product)

        pUrl.appendChild(pImage)
        pUrl.appendChild(pDescription)
        pUrl.appendChild(pPriceButtonWrapper)
        pPriceButtonWrapper.appendChild(pPrice)
        pPriceButtonWrapper.appendChild(pButton)
        pWrapper.appendChild(pUrl)

        return pWrapper
    }

    generatePriceButtonWrapper() {
        const wrapper = document.createElement('div')
        wrapper.classList.add('item-price-button-wrapper')
        return wrapper
    }

    generateProductPrice(product) {
        const priceElement = document.createElement('div')
        priceElement.classList.add('item-price')
        priceElement.innerHTML =
            product.minPrice && product.minPrice !== '' ? `<span>${window.globals.trans('filter.min_price')}</span> ${product.minPrice}` : `&nbsp`
        return priceElement
    }

    generateProductsList(productsData) {
        deleteUrlParam('page')
        deleteUrlParam('productId')
        if (productsData.length > 0) {
            productsData.map((product) => {
                this.productListing.appendChild(this.generateProduct(product))
            })
            new LazyLoadImages(this.productListing)
        } else {
            const noResults = document.createElement('div')
            noResults.classList.add('col-12')
            noResults.classList.add('text-center')
            noResults.innerHTML = `${window.globals.trans('filter.no_results')}`
            this.productListing.appendChild(noResults)
        }
    }

    generateTotalCount(totalCount) {
        this.productCountContainer.innerHTML = totalCount
    }

    updateProductsData(products, key, value) {
        return products.map((product) => {
            product[key] = value
            return product
        })
    }

    render(productsData, totalCount, isLastPage) {
        const productId = parseInt(getUrlParam('productId')) || null
        this.productListing.innerHTML = ''
        this.generateTotalCount(totalCount)
        const updatedProducts = this.updateProductsData(productsData, 'page', 1)
        this.generateProductsList(updatedProducts)
        isLast = isLastPage
        if (!isLast) {
            this.initInfiniteScroll()
        }
        if (this.page > 1) {
            for (let index = 2; index <= this.page; index++) {
                const fData = prepareFilterData()
                this.promises.push(getPage(index, fData))

                if (index === this.page) {
                    startLoader('results-loader')
                    Promise.all(this.promises)
                        .then((result) => {
                            result.forEach((data) => {
                                const productsData = data.data.data.products
                                isLast = data.data.data.isLastPage
                                const updatedProducts = this.updateProductsData(productsData, 'page', this.page)
                                this.generateProductsList(updatedProducts)
                            })
                            this.goToProduct(productId)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            }
        } else {
            this.goToProduct(productId)
        }
    }

    goToProduct(productId) {
        if (productId) {
            const target = document.querySelector(`[data-product-id="${productId}"]`)
            scrollTo(target, -160, 'auto')
            target.firstChild.classList.add('flash')
            stopLoader('results-loader')
        }
    }

    cutBR(text) {
        return text.toString().replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ')
    }

    getNextPage() {
        const fData = { ...prepareFilterData(), ...getSortingParams() }

        if (this.isLoading && !isLast) {
            startLoader('more-results-loader')
            this.page++
            this.isLoading = false
            getPage(this.page, fData)
                .then((data) => {
                    const productsData = data.data.data.products
                    isLast = data.data.data.isLastPage
                    const updatedProducts = this.updateProductsData(productsData, 'page', this.page)
                    this.generateProductsList(updatedProducts)
                    this.isLoading = true
                    stopLoader('more-results-loader')
                })
                .catch((err) => {
                    console.log(err)
                    this.isLoading = true
                    stopLoader('more-results-loader')
                })
        }
    }

    initInfiniteScroll() {
        window.onscroll = () => {
            const isProductTabActive = document.getElementById('productsTab').checked
            let pixelOffset = document.getElementsByClassName('site-footer')[0].clientHeight
            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight - pixelOffset && isProductTabActive) {
                this.getNextPage()
            }
        }
    }
}
